import type { I18nLocaleObject } from '~/config/i18n'

export function getWeekdays(locale: string, format: Intl.DateTimeFormatOptions['weekday'] = 'long') {
  const baseDate = new Date(Date.UTC(2022, 0, 10)) // Start from a Monday
  const weekdays: string[] = []

  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(baseDate.getTime() + i * 24 * 60 * 60 * 1000)
    weekdays.push(new Intl.DateTimeFormat(locale, { weekday: format }).format(currentDate))
  }

  return weekdays
}

export function getHours(locale: string, format: Intl.DateTimeFormatOptions['hour'] = 'numeric') {
  const hours: string[] = []

  for (let i = 0; i < 24; i++) {
    const date = new Date(0, 0, 0, i, 0, 0)
    hours.push(new Intl.DateTimeFormat(locale, { hour: format, minute: format }).format(date))
  }

  return hours
}

export function useI18nLocaleObject() {
  const { locale, locales } = useI18n()
  return computed(() => locales.value.find(l => l.code === locale.value)! as I18nLocaleObject)
}

export function useListFormatter(options?: Intl.ListFormatOptions) {
  const { locale } = useI18n()
  const formatter = new Intl.ListFormat(locale.value, {
    style: 'long',
    type: 'conjunction',
    ...options,
  })
  return (items: string[]) => formatter.format(items)
}
