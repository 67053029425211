<script setup lang="ts">
const props = withDefaults(defineProps<{
  items: number
  delay?: number
  childClass?: string
}>(), {
  delay: 3000,
})

const currentIndex = ref(0)
const intervalId = ref<NodeJS.Timeout | null>(null)

function getStyle(index: number) {
  let left, animation
  if (currentIndex.value === index) {
    left = '0'
    animation = 'carousel-slide-bounce 0.4s ease-out'
  }
  else if ((index + 1) % props.items === currentIndex.value) {
    left = '-100%'
    animation = 'carousel-slide-swipe 0.3s ease-out'
  }
  else {
    left = '100%'
  }

  return {
    transform: `translateX(${left})`,
    animation,
  }
}

onMounted(() => {
  intervalId.value = setInterval(() => {
    currentIndex.value = (currentIndex.value + 1) % props.items
  }, props.delay)
})

onUnmounted(() => {
  if (intervalId.value)
    clearInterval(intervalId.value)
})
</script>

<template>
  <div class="relative of-hidden">
    <div
      v-for="(item, index) in items" :key="index"
      :data-id="`slide-${index}${currentIndex === index ? '-active' : ''}`"
      :style="getStyle(index)"
      :class="childClass"
    >
      <slot :item="item" :index="index" :name="`slide-${index}`" />
    </div>
  </div>
</template>
