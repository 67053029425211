<script setup lang="ts">
const props = defineProps<{
  childClass: string
  wrapperClass: string
}>()

const {
  locale,
  t,
} = useI18n()

const days = computed(() => {
  const {
    0: monday,
    4: friday,
  } = getWeekdays(locale.value, 'short')
  return `${monday} - ${friday}`
})
const hours = computed(() => {
  const {
    6: six,
    18: eighteen,
  } = getHours(locale.value)
  return `${six} - ${eighteen}`
})
</script>

<template>
  <NTinyCarousel :items="3" class="h-full" :child-class="props.wrapperClass">
    <template #slide-0>
      <span :class="props.childClass" n="xs sm:sm">
        <NIcon icon="i-heroicons:truck" class="n-button-icon" /> {{ t('shipping.within_24_hours') }}
      </span>
    </template>

    <template #slide-1>
      <span :class="props.childClass" n="xs sm:sm">
        <NIcon icon="i-heroicons:clock" class="n-button-icon" /> {{
          t('customer_service.days_and_hours', {
            hours,
            days,
          })
        }}
      </span>
    </template>

    <template #slide-2>
      <span :class="props.childClass" n="xs sm:sm">
        <NIcon icon="i-heroicons:shield-check" class="n-button-icon" /> {{ t('product.shipping_guarantee.label') }}
      </span>
    </template>
  </NTinyCarousel>
</template>
